<template>
  <div class="product-finder-banner">
    <div class="banner-content">
      <!-- Background Image -->
      <img
        class="banner-image"
        src="../assets/images/banner-image.jpeg"
        alt=""
      />

      <!-- Content Container -->
      <div class="content-wrapper">
        <div class="banner-text-container">
          <p class="product-finder-header">Check out the Product Finder!</p>
          <p class="product-finder-description">
            Answer a few simple questions to find the Jamf product that best
            fits your organization's needs.
          </p>
        </div>
        <div class="button-container">
          <button class="lets-go-btn" @click="navigateToProductFinder()">
            Let's go!
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductFinderBanner",
  methods: {
    navigateToProductFinder() {
      const env = process.env.NODE_ENV;
      let route =
        env === "production"
          ? `https://store-configure.jamf.com/productfinder`
          : `https://store-configure.jamfnebula.com/productfinder`;
      window.open(route);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/main.scss";
.product-finder-banner {
  display: flex;
  justify-content: center;
  margin-bottom: 96px;
  width: 100%;
  padding: 0 24px;
}

.banner-content {
  width: 100%;
  max-width: 1104px;
  height: 89px;
  background: linear-gradient(to bottom, #8a008d, #0b34c5);
  border: 1px solid #5856d6;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 120%;
  width: 100%;
  opacity: 0.1;
  object-fit: cover;
  transform: translateY(-140px); /* Your working transform value */
}

@media (max-width: 768px) {
  .banner-image {
    transform: translateY(0); /* Remove transform on mobile */
    height: 100%; /* Return to normal height */
  }
}

.content-wrapper {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

.banner-text-container {
  P {
    color: white !important;
  }
  .product-finder-header {
    padding-top: 10px;
    font-size: 16px !important;
    font-weight: 700 !important;
    margin: 0;
  }
  .product-finder-description {
    padding-top: 8px;
    padding-bottom: 10px;
    font-size: 13px !important;
    font-weight: 400 !important;
    margin: 0;
  }
}

.button-container {
  display: flex;
  align-items: center;
  gap: 16px;

  .learn-more-btn {
    color: white !important;
    background: transparent !important;
    padding: 8px 16px !important;
    font-weight: 400 !important;
    border: none !important;
    cursor: pointer !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .lets-go-btn {
    background: rgba(255, 255, 255, 0.2) !important; // Semi-transparent white
    color: white !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    border: none !important;
    font-weight: 400 !important;
    cursor: pointer !important;
    width: 120px;

    &:hover {
      background: rgba(255, 255, 255, 0.3) !important;
    }
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 12px;
  }

  .banner-content {
    height: auto;
    padding: 16px 0;
  }
}
</style>
