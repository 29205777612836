<template>
  <div>
    <ProductFinderBanner />
  <div class="home-content">
    <h3 id="home-title">Jamf Store</h3>
    <h4 id="home-subtitle">Select your organization type</h4>
    <div class="card-container" >
      <jamf-card v-bind="props" @click="goToBusiness()" id="industry-card">
        <template #image-top>
          <div id="industry-photo-container">
            <img id="industry-photo" src="../assets/images/jamf-business.png" alt="a man and a woman at a table with laptops in front of them talking to another person across from them"/>
          </div>
          <div id="industry-subtitle">
            <div id="industry-title-row">
              <h4 id="industry-card-title">Business</h4>
              <jamf-icon id="subtitle-arrow" :data="arrowRight" width="20" />
            </div>
            <jamf-link id="text-color-override" to="#">Check out the Business Store</jamf-link>
          </div>
        </template>
      </jamf-card>
      <jamf-card v-bind="props" @click="goToEducation()" id="industry-card">
        <template #image-top>
          <div id="industry-photo-container">
            <img id="industry-photo" src="../assets/images/jamf-education.png" alt="an image of two children sitting on the floor of a library aisle reading on iPads"/>
          </div>
            <div id="industry-subtitle">
            <div id="industry-title-row">
              <h4 id="industry-card-title">Education</h4>
              <jamf-icon id="subtitle-arrow" :data="arrowRight" width="20" />
            </div>
            <jamf-link id="text-color-override" to="#">Check out the Education Store</jamf-link>
          </div>
        </template>
      </jamf-card>
    </div>
    <h4 id="subsection-title">Add-Ons</h4>
    <div id="subsection-container">
      <jamf-card id="subsection-card" @click="goToAddOns()">
        <template #image>
            <img id="subsection-card-photo" src="../assets/images/jamf-add-ons.png"/>
        </template>
        <div id="subsection-card-title-row">
          <h3 id="subsection-card-title">Industry Add-Ons</h3>
          <jamf-icon id="subsection-arrow" :data="arrowRight" width="20" />
        </div>
        <jamf-link id="text-color-override" to="#">Add-ons</jamf-link>
      </jamf-card>
    </div>
    <h4 id="next-subsection">Other</h4>
    <div id="subsection-container">
      <jamf-card id="subsection-card" @click="goToTraining()">
        <template #image>
          <img id="subsection-card-photo" src="../assets/images/jamf-training.png"/>
        </template>
          <div id="subsection-card-title-row">
            <h3 id="subsection-card-title">Training Courses</h3>
            <jamf-icon id="subsection-arrow" :data="arrowRight" width="20" />
          </div>
          <jamf-link id="text-color-override" to="#"></jamf-link>
      </jamf-card>
      <jamf-card id="subsection-card" @click="goToMarketplace()">
        <template #image>
          <img id="subsection-card-photo" src="../assets/images/jamf-marketplace.png"/>
        </template>
          <div id="marketplace-card-title-row">
            <h3 id="subsection-card-title">Jamf Marketplace</h3>
            <jamf-icon id="subsection-arrow" :data="arrowRight" width="20" />
          </div>
          <div class="text-wrap-padding">
            <jamf-link id="text-color-override" to="#">A central location for you to find, learn about and use valuable tools that integrate with and extend the Jamf platform.</jamf-link>
          </div>
      </jamf-card>
      <jamf-card id="subsection-card" @click="goToJNUC()">
        <template #image>
          <img id="subsection-card-photo" src="../assets/images/jamf-JNUC.png"/>
        </template>
          <div id="subsection-card-title-row">
            <h3 id="subsection-card-title">Jamf Nation User Conference</h3>
            <jamf-icon id="subsection-arrow" :data="arrowRight" width="20" />
          </div>
          <jamf-link id="text-color-override" to="#">The largest gathering of Apple system administrators in the world.</jamf-link>
      </jamf-card>
      <jamf-card id="subsection-card" @click="goToSwag()">
        <template #image>
          <img id="subsection-card-photo" src="../assets/images/jamf-swag-store.png"/>
        </template>
          <div id="subsection-card-title-row">
            <h3 id="subsection-card-title">Swag Store</h3>
            <jamf-icon id="subsection-arrow" :data="arrowRight" width="20" />
          </div>
          <jamf-link id="text-color-override" to="#">Check out our Jamf merchandise
</jamf-link>
      </jamf-card>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import { JamfCard, JamfLink, JamfIcon } from '@jamf/design-system-vue'
import arrowRight from '@icon/arrow-2-right.svg';
import checkCircle from '@icon/check-circle.svg';
import ProductFinderBanner from '@/components/ProductFinderBanner.vue';

export default {
  name: 'HomeView',
  components: {
    JamfCard,
    JamfLink,
    JamfIcon,
    ProductFinderBanner
  },
  data() {
    return {
      arrowRight,
      checkCircle
    }
  },
  methods: {
    goToBusiness() {
      this.$router.push({ name: 'business' })
      this.$store.dispatch("trackCTAClicked", {
        clicked: 'Company Industry - Business',
        product: 'Jamf Store'
      })
    },
    goToEducation() {
      this.$router.push({ name: 'education' })
      this.$store.dispatch("trackCTAClicked", {
        clicked: 'Company Industry - Education',
        product: 'Jamf Store'
      })
    },
    goToAddOns() {
      this.$router.push({ name: 'addons' })
      this.$store.dispatch("trackCTAClicked", {
        clicked: 'Industry Add-Ons',
        product: 'Industry Add-Ons'
      })
    },
    goToTraining() {
      window.location.assign('https://account.jamf.com/training/courses')
      this.$store.dispatch("trackCTAClicked", {
        clicked: 'Training Courses',
        product: 'Training Courses'
      })
    },
    goToMarketplace() {
      window.location.assign('https://marketplace.jamf.com')
      this.$store.dispatch("trackCTAClicked", {
        clicked: 'Jamf Marketplace',
        product: 'Jamf Marketplace'
      })
    },
    goToJNUC() {
      window.location.assign('https://www.jamf.com/events/jamf-nation-user-conference')
      this.$store.dispatch("trackCTAClicked", {
        clicked: 'Jamf Nation User Conference',
        product: 'Jamf Nation User Conference'
      })
    },
    goToSwag() {
      window.location.assign('https://jamf.idworks.com/cat_675.php')
      this.$store.dispatch("trackCTAClicked", {
        clicked: 'Jamf Swag Store',
        product: 'Jamf Swag Store'
      })
    }
  }
}
</script>

<style lang="scss">
  @import "../assets/styles/main.scss";

  #home-title {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  #home-subtitle {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 88px;
  }

  .home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #industry-card {
    width: 355.83px;
    height: 397.17px;
    background: #FFFFFF;
    border-radius: 20px;
    border: none;
    overflow: hidden;
    border: none;
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.06);
    filter: drop-shadow(0px 0px 38px rgba(0, 0, 0, 0.06)); 
    &:hover {
      box-shadow: 0 0 0 3px rgba(30, 121, 233, 1);
    }
  }

  #industry-card-title {
    margin-bottom: 12px;

  }

  #industry-photo-container {
    width: 355.83px;
    height: 300px;
    border-radius: 17px 17px 0px 0px;
    overflow: hidden;
  }

  #industry-photo {
    width: auto;
    height: 100%;
    margin: 0;
  }

  .card-container {
    display: flex;
    gap: 30px;
    margin-bottom: 96px;
  }

  #industry-subtitle {
    margin-left: 33px;
    margin-top: 10px;
  }

  #industry-title-row {
    display: flex;
    flex-direction: row;
  }

  #subtitle-arrow {
    margin-left: 170px;
    padding-bottom: 8px;
  }

  #subsection-title {
    margin-top: 15px;
  }

  #next-subsection {
    padding-top: 106px;
  }

  #subsection-card-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 8px;
  }

  #marketplace-card-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 8px;
  }

  #subsection-card-title {
    white-space: nowrap;
  }

  #subsection-card-photo {
    width: 173px;
    height: 124px;
    border-radius: 20px 0px 0px 20px;
  }

  #subsection-card {
    margin-top: 30px;
    width: 741.66px;
    height: 124px;
    background: #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
    border: none;
    box-shadow: 0 0 38px rgba(0, 0, 0, 0.06);
    filter: drop-shadow(0px 0px 38px rgba(0, 0, 0, 0.06)); 
    &:hover {
      box-shadow: 0 0 0 3px rgba(30, 121, 233, 1);
      #subsection-card-photo {
        border-radius: 17px 0px 0px 17px
      }
    }
  }

  #subsection-arrow {
    padding-right: 28px;
  }

  #text-color-override {
    color: black;
  }

  .text-wrap-padding {
    padding-bottom: 10px;
  }

  @media screen and (max-width: 770px) {
    .card-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 96px;
    }
    #subsection-card {
      margin-top: 30px;
      width: 600px;
      height: 124px;
      background: #FFFFFF;
      border-radius: 20px;
    }
    #marketplace-card-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1px;
    padding-bottom: 8px;
    }
  }

  @media screen and (max-width: 620px) {
  #subsection-card {
    margin-top: 30px;
    width: 500px;
    height: 124px;
    background: #FFFFFF;
    border-radius: 20px;
    &:hover {
      box-shadow: 0 0 0 3px rgba(30, 121, 233, 1);
      #subsection-card-photo {
        border-radius: 17px 0px 0px 17px
      }
    }
  }
}

@media screen and (max-width: 620px) {
    #subsection-card-photo {
      width: 173px;
      height: 150px;
      border-radius: 20px 0px 0px 20px;
    }
    #subsection-card {
      margin-top: 30px;
      width: 500px;
      height: 150px;
      background: #FFFFFF;
      border-radius: 20px;
      &:hover {
        box-shadow: 0 0 0 3px rgba(30, 121, 233, 1);
        #subsection-card-photo {
          border-radius: 17px 0px 0px 17px
        }
      }
    }
  }
  @media screen and (max-width: 510px) {
    #subsection-card-photo {
      width: 173px;
      height: 180px;
      border-radius: 20px 0px 0px 20px;
    }
    #subsection-card {
      margin-top: 30px;
      width: 370px;
      height: 200px;
      background: #FFFFFF;
      border-radius: 20px;
    }
  }
  @media screen and (max-width: 503px) {
    #subsection-card-photo {
      width: 173px;
      height: 200px;
      border-radius: 20px 0px 0px 20px;
    }
    #subsection-card {
      margin-top: 30px;
      width: 370px;
      height: 200px;
      background: #FFFFFF;
      border-radius: 20px;
      padding-right: 5px;
      &:hover {
        box-shadow: 0 0 0 3px rgba(30, 121, 233, 1);
        #subsection-card-photo {
          border-radius: 17px 0px 0px 17px
        }
      }
    }
    #subsection-card-title-row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 8px;
    }
    #subsection-card-title {
      white-space: normal;
    }
    #home-subtitle {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 29px;
}
#marketplace-card-title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
    }
    .Card_card-body_vvc1Y {
      margin-top: 3px;
    }
  }
</style>